import { ClipLoader } from "react-spinners";
import { LoadingStatus } from "../../../../common/commonSlice";
import { GetManualInputDataItem } from "../../hyperFlowAPI";
import LoadingOverlay from "react-loading-overlay-ts";

export interface ManualInputComponentProsp {
  status: LoadingStatus;
  items: Array<GetManualInputDataItem>;
  onChange: (id: string, value: string) => void;
  onSubmit: () => void;
}

const getItemList = (
  items: Array<GetManualInputDataItem>,
  onChange: (id: string, value: string) => void
) => {
  return items.map((item) => {
    return (
      <div key={item.id} className="row">
        <div className="col-12">
          <label
            htmlFor={item.id}
            className="form-label"
            style={{ color: "white" }}
          >
            {item.name}
          </label>
          <input
            id={item.id}
            name={item.id}
            type="text"
            onChange={(e) => {
              onChange(item.id, e.target.value);
            }}
            value={item.value}
            style={{
              border: "0.5px solid rgba(255,255,255,0.2)",
              borderRadius: "6px",
              background: "rgba(255,255,255,0.05)",
              minHeight: "30px",
              fontSize: "12px",
              fontWeight: "600",
              textAlign: "start",
              width: "100%",
              color: "#FFF",
            }}
          />
        </div>
      </div>
    );
  });
};

export const ManualInputComponent = (props: ManualInputComponentProsp) => {
  switch (props.status) {
    case "pending":
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <LoadingOverlay
            active={true}
            spinner={<ClipLoader color="white" size="100px" />}
          />
        </div>
      );
    case "resolved":
      return (
        <div
          style={{
            width: "90%",
            height: "100%",
            justifyContent: "center",
            alignContent: "center",
            paddingLeft: "20px",
          }}
        >
          {getItemList(props.items, props.onChange)}
          <button
            type="submit"
            onClick={props.onSubmit}
            style={{
              width: "100%",
              height: "40px",
              borderRadius: "6px",
              background: "rgba(255,255,255,0.2)",
              color: "white",
              fontWeight: "600",
              fontSize: "12px",
              border: "0px",
              marginTop: "20px",
            }}
          >
            Confirmar Cambios
          </button>
        </div>
      );
    case "rejected":
      return (
        <div
          className="flex-col text-normal text-medium primary-color"
          style={{
            textAlign: "center",
            color: "white",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            border: "1px solid rgba(0,0,0,0.2)",
            width: "300px",
          }}
        >
          Error al cargar los datos
        </div>
      );
    case "idle":
      return (
        <div
          className="flex-col text-normal text-medium primary-color"
          style={{
            textAlign: "center",
            color: "white",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            border: "1px solid rgba(0,0,0,0.2)",
            width: "300px",
          }}
        >
          No hay datos para mostrar
        </div>
      );
  }
};
